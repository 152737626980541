.MuiAvatar-root{
    margin: 30px auto;
}
.login-container .MuiTypography-root{
    margin: 0 auto;
}
.childTable{
    background: #f2f2f2;
}
.MuiTableHead-root th {
    font-weight: bold;
}
.makeStyles-reportSearchBox-8{
    margin-bottom: 40px!important;
}

.report-search-button {
    margin-left: 10px!important;;
    padding: 13px!important;
    background-color: #558db1!important;
}
.makeStyles-content-9{
    height: auto!important;
}
.logo-image{
    width: 80%;
}
.MuiAppBar-colorPrimary{
    background-color: #0063a1!important;

}
.profileMenuButton{
    right: 10%;
    color: #fff!important;
}
.MuiTablePagination-root{
    border-bottom:none!important;
  }
  .no-records{
      text-align: center!important;
      padding: 15px!important;
  }
  #setting-menu{
      float: right;
  }
  .MuiContainer-root.makeStyles-container-3{
    padding-top: 16px;
    padding-bottom: 16px;
  }
 .show-loader  .MuiContainer-root.MuiContainer-maxWidthSm{
    z-index: 999999;
    width: 100% !important;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0.8;
    max-width: 100%;

  }

  .setting-menu{
     
      z-index: 999;
  }

  #downloadButton { order: 1 }

  .MuiFormLabel-root{
      font-size: 12px!important;
  }
  .MuiMenuItem-root {
    font-size: 12px!important;
  }
  .MuiSelect-select{
    font-size: 12px!important;
  }
  .MuiInputBase-input{
    font-size: 12px!important;
  }
  .MuiAutocomplete-popper li{
    font-size: 12px!important;
   
  }
  .multiple-autocomplete .MuiAutocomplete-inputRoot{
      max-height: 27px;
      font-size: 12px!important;
  }
  .multiple-autocomplete .MuiInputBase-root {
    line-height: 2.1876em!important;
  }
  .multiple-autocomplete .MuiAutocomplete-inputRoot {
    flex-wrap: nowrap!important;
}
h6.MuiTypography-root{
    font-size: 14px!important; 
}
h2.MuiTypography-root{
    font-size: 14px!important; 
}
.MuiSelect-selectMenu{
    max-height: 14px;
}
.MuiPickersDay-daySelected{
    background-color: #548db1!important;
}

.history-table .MuiToolbar-root div:nth-child(2) {
    display: flex;
    justify-content: flex-end;
}
.history-table .MuiToolbar-root span:nth-child(1) {
    order:2
}
.history-table .MuiToolbar-root span:nth-child(2) {
    order:3
}
  .date-range-picker .MuiTypography-root{
      font-size: 14px;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: #51b8e8!important;
}
.highcharts-markers image.highcharts-point{
    filter: grayscale(60%); 
}

/*.MuiOutlinedInput-root{
  width: 150px;
  margin-top: 10px;
  margin-bottom: 13px;
  height: 32px;
}
.MuiDrawer-docked {
    flex: 0 0 auto!important;
}

.MuiAppBar-positionAbsolute{
    top: 0;
    left: auto;
    right: auto!important;
    position: absolute;
}
.MuiDrawer-paperAnchorLeft{
    position: relative!important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
    padding-top:0!important;
    padding-right: 55px!important;
}
.MuiDrawer-paper{
    z-index: auto!important;
}*/

.MuiAutocomplete-inputRoot[class*="MuiInput-root"]{
    padding-bottom: 0px!important;
    font-size: 12px!important;
    padding-right:0px!important;
}

.multiple-autocomplete .MuiAutocomplete-option{
    /*max-height: 30px!important;*/
    padding:2px!important;
    font-size: 12px!important;
    
}



.MuiAutocomplete-groupLabel{
    max-height: 40px;
}
.supplierSelectorBox label{
    color:#fff!important;
}
.supplierSelectorBox input{
    color:#fff!important;
}
.supplierSelectorBox .MuiAutocomplete-clearIndicator{
    background:#fff!important;
    width: 20px;
    height: 20px;
}

.MuiAutocomplete-listbox{
    border: 1px solid #e0dada;
}


.MuiFormControlLabel-label{
    font-size: 12px!important;
}
.MuiChip-deletable{
    height:25px!important
}
.MuiDialogTitle-root{
    background-color: #f2f2f2;
}
.MuiInputBase-root.Mui-disabled{
    color:#000000!important
}

@media (min-width: 1280px)
.MuiContainer-maxWidthLg {
    max-width: none;
}